body{
  margin:0;
  background-color: #ffecc6;
  font-family: Arial, Helvetica, sans-serif;
}
*{
  box-sizing: border-box;
}
.book-section{
  height: 100vh;
  width: 100%;
  padding: 40px 0;
  text-align: center;
}
.book-section > .container{
  height: 400px;
  width: 500px;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 2%;
  margin-bottom: 30px;
  perspective: 1200px;
}
.container > .right{
  position: absolute;
  height: 100%;
  width: 50%;
  transition: 0.7s ease-in-out;
  transform-style: preserve-3d;
}
.book-section > .container > .right{
  right:0;
  transform-origin: left;
  border-radius: 10px 0 0 10px;
}
.right > figure.front, .right > figure.back{
  margin: 0;
  height: 100%;
  width: 100%;
  position: absolute;
  left:0;
  top:0;
  background-size: 200%;
  background-repeat: no-repeat;
  backface-visibility: hidden;
  background-color: white;
  overflow: hidden;
}
.right > figure.front{
  background-position: right;
  border-radius: 0 10px 10px 0;
  box-shadow: 2px 2px 15px -2px rgba(0,0,0,0.2);
}
.right > figure.back{
  background-position: left;
  border-radius: 10px 0 0 10px;
  box-shadow: -2px 2px 15px -2px rgba(0,0,0,0.2);
  transform: rotateY(180deg);
}
.flip{
  transform: rotateY(-180deg);
}
.flip::before{
  content: "";
  position: absolute;
  top:0;
  left:0;
  z-index: 10;
  width: 100%;
  height: 100%;
  border-radius: 0 10px 10px 0;
  background-color: rgba(0,0,0,0.1);
}
.book-section > button{
  border: 2px solid #ef9f00;
  background-color: transparent;
  color: #ef9f00;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  margin: 10px;
  transition: 0.3s ease-in-out;
}
.book-section > button:focus, .book-section > button:active{
  outline: none;
}
.book-section > p{
  color: rgba(0,0,0,0.7);
  font-family: calibri;
  font-size: 24px;
}
.book-section > p > a{
  text-decoration: none;
  color: #ef9f00;
}
.book-section > button:hover{
  background-color: #ef9f00;
  color: #fff;
}
.front#cover, .back#back-cover{
  background-color: #ffcb63;
  font-family: calibri;
  text-align: left;
  padding: 0 30px;
}
.front#cover h1{
  color: #fff;
}
.front#cover p{
  color: rgba(0,0,0,0.8);
  font-size: 14px;
}

img {
/* /width:100%; */
  height:100%;
  width:fit-content
}
.signature {
  text-align: right;
  color: #333;
  padding-right: 20px
}

.card {
  text-align: left;
  color: #333;
  padding-left: 20px;
  padding-right: 20px
}